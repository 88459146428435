import { skills } from "../../data"

const Skills = () => {
    return (
    <section className="skills" id="skills">
        <div className="container mx-auto">
            <div className="grid grid-cols-6 justify-items-center">
                {skills.map((skill, index) => {
                    return (
                        <div>
                            <img src={skill.image} alt='' />
                        </div>
                    );
                })}
            </div>
        </div>
    </section>
  
  );
};

export default Skills