import "./productlist.css"
import Product from "../productCard/product"
import {products} from "../../data"

const Productlist = () => {
    return (
        <div className="pl" data-aos="fade-up"
        data-aos-duration="3000">
            <div className="pl-texts">
            <h1 className="pl-title">Nézd meg eddigi munkáimat!</h1>
            {/* <p className="pl-desc">Work hard...</p> */}
            </div>
            <div className="pl-list">
                 {products.map((item) => (
                    <Product key={item.id} img={item.img} link={item.link}/>
                 ))}    
            </div>
        </div>
    );
};

export default Productlist