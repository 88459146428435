import "./contact.css"
import Phone from "../../images/phone.jpg"
import Email from "../../images/email.jpg"
import Address from "../../images/address.png"
import { useContext, useRef, useState } from "react";
import emailjs from '@emailjs/browser';
import { ThemeContext } from "../../context";

const Contact = () => {
    const formRef = useRef()
    const [done, setDone] = useState(false)
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;

        const handleSubmit = (e)=>{
            e.preventDefault();
            emailjs.sendForm(
                'service_39fulya',
                'template_296gt2u',
                formRef.current,
                'Kwyyf1bwbvzVLrgyp'
                )
        .then((result) => {
            console.log(result.text);
            setDone(true)
        }, (error) => {
            console.log(error.text);
        });
        };
   
    return (
        <div className="c" id="contact">
            <div className="c-bg"></div>
                <div className="c-wrapper">
                    <div className="c-left">
                        <h1 className="c-title">Ha már megálmodtad valósítsuk is meg! </h1>
                        <div className="c-info">
                        <div className="c-info-item">
                            <img src={Phone}
                             alt=""
                              className="c-icon" />
                              +36203420086
                        </div>
                        <div className="c-info-item">
                            <img src={Email}
                             alt=""
                              className="c-icon" />
                              benewebdesign@gmail.com
                        </div>
                        <div className="c-info-item">
                            <img src={Address}
                             alt=""
                              className="c-icon" />
                              1201, Budapest Kossuth Lajos u. 32
                        </div>
                        </div>
                    </div>
                    <div className="c-right">
                    <p className="c-desc">Amennyiben elakadtál, <b>Keress bizalommal</b> én segítek, hogy minden igényednek megfelelő weboldala legyen a vállalkozásódnak.</p>
                        <form ref={formRef} onSubmit={handleSubmit}>
                            <input style={{backgroundColor: darkMode && "#333"}} type="text" placeholder="Név" name="user_name" />
                            <input style={{backgroundColor: darkMode && "#333"}} type="text" placeholder="Tárgy" name="user_subject" />
                            <input style={{backgroundColor: darkMode && "#333"}} type="text" placeholder="Email" name="user_email" />
                            <textarea style={{backgroundColor: darkMode && "#333"}} name="message" placeholder="Írj nekünk!" rows="5"></textarea>
                            <button>Küldés</button>
                            {done && "Üzenet elküldve! Köszönjük..."}
                        </form>
                    </div>
                </div>
            
        </div>
    );
};

export default Contact