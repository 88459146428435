import "./Intro.css"
import Me from "../../images/intro.jpg"

const Intro = () => {
    return (
        <div className="i" id="home">
            <div className="i-left">
                <div className="i-left-wrapper">
                    <h2 className="i-intro">Üdv,</h2>
                    <h1 className="i-name">Bene Ádám vagyok</h1>
                    <div className="i-title">
                        <div className="i-title-wrapper">
                            <div className="i-title-item">Webfejlesztő</div>
                            <div className="i-title-item">Web Designer</div>
                        </div>
                    </div>
                    <p className="i-desc">
                        Weboldal készítés / felújítás Web design, karbantartás
                    </p>
                </div>

            </div>
            <div className="i-right">
                <div className="i-bg"></div>
                { <img src={Me} alt="me" className="i-img" />  } 
            </div>
            
        </div>
    )
}

export default Intro