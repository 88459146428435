
//  icons
import {
  
  FiInstagram,
  FiFacebook,
  FiMail
} from "react-icons/fi";


// skills images
import SkillImg1 from './images/skills/html5.png';
import SkillImg2 from './images/skills/css3.png';
import SkillImg3 from './images/skills/js.png';
import SkillImg4 from './images/skills/reactjs.png';
import SkillImg6 from './images/skills/nodejs.png';

import SkillImg8 from './images/skills/figma.png';

export const products = [
    {
        id: 1,
        img: "https://kephost.net/p/2022/52/7897_ce678dfcac44.jpg",
        link: "https://andreaapartman.com/",
    },
    {
        id: 2,
        img: "https://kephost.net/p/2022/52/2727_cda4669f8d03.jpg",
        link: "https://rbalazs.synology.me/balogbence/",
    },
    {
        id: 3,
        img: "https://www.iloveimg.com/img/homepage-product-2.png",
        link: "https://andreaapartman.com/",
    },
  ];

 
// skill
export const skills = [
  {
    image: SkillImg1,
  },
  {
    image: SkillImg2,
  },
  {
    image: SkillImg3,
  },
  {
    image: SkillImg4,
  },
  {
    image: SkillImg6,
  },
  {
    image: SkillImg8,
  },
];

// social
export const social = [
  {
    icon: <FiFacebook />,
    href: 'https://www.facebook.com/profile.php?id=100089892916421',
  },
  {
    icon: <FiInstagram />,
    href: '',
  },
  {
    icon: <FiMail />,
    href: '',
  },
];
