import "./about.css";
import design from "../../images/me.jpg";
import Award from "../../images/webdesign.jpg"

 
const About = () => {
    return (
        <div className="about" id="about">
                <div className="about-left" data-aos="fade-right">
                    <div className="about-card bg"></div>
                    <div className="about-card">
                        <img src={design} alt="design" className="about-img" />
                    </div>
                </div>
            
            <div className="about-right" data-aos="fade-up-left">
                <h1 className="about-title">Üdvözöllek!</h1>
                <p className="about-sub">
                    
                </p>
                <p className="about-desc">
                    Ádám vagyok, a BeneWebDesign megálmodója. Webdesignnal és Weboldal készítéssel foglalkozom. Mivel az online jelenlét a mai világban
                    egy sikeres vállalkozás alapja, érdemes mihamarabb jelen lenni. Számomra a weboldalak készítése olyan feladat, amit szívvel-lélekkel végzek. Folyamatosan fejlesztem magam, hogy minél színvonalasabb munkát tudjak átadni leendő ügyfeleimnek.

                </p>
                <div className="about-award">
                    <img src={Award} alt="" className="about-award-img"/>
                    <div className="a-awards-texts">
                        <h4 className="a-award-title">Ha készen állsz...</h4>
                        <p className="a-award-desc">Nézz szét különböző csomagajaim között és válaszd a számodra megfelelőt. Ha bizonytalan lennél, akkor a konzultáció során biztosan megtaláljuk mire lenne szükséged!</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About