import { useContext } from "react";
import About from "./components/about/about";
import Contact from "./components/contact/contact";
import Footer from "./components/footer/Footer";
import Intro from "./components/Intro/Intro";
import Productlist from "./components/productList/produclist";
import Skills from "./components/Skills/Skills";
import Toggle from "./components/toggle/Toggle";
import { ThemeContext } from "./context";

// import Navbar from "./components/navbar/Navbar";
import Card from "./components/Card/Card";

const App = () => {

  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;
  return <div style={{ backgroundColor:darkMode ? "#222" : "white",
   color: darkMode && "white",}}>
    {/* <Navbar/> */}
    <Toggle/>
    <Intro/>
    <About/>
    <Skills/>
    <Card/>
    {/* <Card/> */}
    <Productlist/>
    <Contact/>
    <Footer/>
    </div>;
};

export default App;