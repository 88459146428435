import React from 'react'

export default function Card() {
  return (
    <section>
        <div>
            <h3 className='text-3xl py-1 mt-6 text-center'>Csomagok</h3>
            <p className='text-md py-2 leading-8 text-center'>Válassz a csomagjaim közül</p>
        </div>
        <div className='lg:flex gap-x-0 justify-around'>
            <div className='bg-emerald-500 text-center shadow-lg p-10 rounded-xl my-10'>
                {/* <Image src={} width={100} height={100} /> */}
                <h2 className='text-lg font-medium pt-8 pb-2'>Weboldal készítés</h2>
                <span className='text-2xl font-bold pt-8 pb-2'>100.000</span>
                <span className='text-sm font-semibold leading-6 tracking-wide pt-8 pb-2'> Ft-tól</span>
                <p className='py-2'>Egyszerű bemutatkozó oldal</p>
                <h4 className='py-4 '>Mit tartalmaz</h4>
                <p className='py-1'>Ingyenes konzultáció</p>
                <p className='py-1'>Tervezés</p>
                <p className='py-1'>Domain és tárhely</p>
                <p className='py-1'>SSL tanusítvány</p>
                <p className='py-1'>Responsive design</p>
            </div>
            <div className='bg-emerald-500 text-center shadow-lg p-14 rounded-xl my-10'>
                {/* <Image src={} width={100} height={100} /> */}
                <h2 className='text-lg font-medium pt-8 pb-2'>Weboldal készítés</h2>
                <span className='text-2xl font-bold pt-8 pb-2'>150.000</span>
                <span className='text-sm font-semibold leading-6 tracking-wide pt-8 pb-2'> Ft-tól</span>
                <p className='py-2'>Egyedi design</p>
                <h4 className='py-4 '>Mit tartalmaz</h4>
                <p className='py-1'>Ingyenes konzultáció</p>
                <p className='py-1'>Tervezés</p>
                <p className='py-1'>Domain és tárhely</p>
                <p className='py-1'>SSL tanusítvány</p>
                <p className='py-1'>Responsive design</p>
                <p className='py-1'>1 éves karbantartás</p>
            </div>
            <div className='bg-emerald-500 text-center shadow-lg p-10 rounded-xl my-10'>
                {/* <Image src={} width={100} height={100} /> */}
                <h2 className='text-lg font-medium pt-8 pb-2'>Webshop készítés</h2>
                <span className='text-2xl font-bold pt-8 pb-2'>200.000</span>
                <span className='text-sm font-semibold leading-6 tracking-wide pt-8 pb-2'> Ft-tól</span>
                <p className='py-2'>Webshop készítése</p>
                <h4 className='py-4 '>Mit tartalmaz</h4>
                <p className='py-1'>Ingyenes konzultáció</p>
                <p className='py-1'>Tervezés</p>
                <p className='py-1'>Termékek feltöltése</p>
                <p className='py-1'>Kuponok beállítása</p>
                <p className='py-1'>Fizetési módok beállítása</p>
            </div>
         
        </div>
    </section>
    )
}
